<script
    lang="ts"
    setup
>
    type Props = {
        modelValue?: boolean
        defaultValue?: boolean
        preventValueChange?: boolean
        headerClass?: string
        bodyClass?: string
        titleClass?: string
    }

    type Emit = {
        (event: 'update:modelValue', value: boolean): void
        (event: 'toggled', value: boolean): void
        (event: 'before-change'): void
    }

    const props = withDefaults(defineProps<Props>(), {
        modelValue: undefined,
        defaultValue: false,
        preventValueChange: false,
        headerClass: undefined,
        bodyClass: undefined,
        titleClass: undefined
    })

    const emit = defineEmits<Emit>()

    const defaultValue = ref(props.defaultValue)

    const opened = computed<boolean>({
        get() {
            if (props.modelValue !== undefined) {
                return props.modelValue
            } else {
                return defaultValue.value
            }
        },
        set(value) {
            emit('before-change')

            if (props.preventValueChange) {
                return
            }

            if (props.modelValue !== undefined) {
                emit('update:modelValue', value)
            } else {
                defaultValue.value = value
            }

            emit('toggled', value)
        }
    })
</script>

<template>
    <div>
        <div
            key="header"
            :class="[
                `
                    flex
                    items-center
                    justify-between
                    h-[36px]
                    cursor-pointer
                    select-none
                `,
                props.headerClass
            ]"
            @click="opened = !opened"
        >
            <div
                :class="[
                    `
                        flex
                        items-center
                        flex-1
                        h-[inherit]
                        text-[16px]
                        font-medium
                        leading-[130%]
                    `,
                    props.titleClass
                ]"
            >
                <slot name="title"/>

                <div class="ml-auto">
                    <slot
                        name="header-right"
                        :opened="opened"
                    >
                        <AppIconChevronDown
                            size="20"
                            :rotate="!opened"
                        />
                    </slot>
                </div>
            </div>
        </div>

        <div
            v-if="$slots['before-body']"
            key="before-body"
        >
            <slot name="before-body"/>
        </div>

        <AppTransitionCollapse
            with-vanishing-self
            :enter-duration="0.12"
            :leave-duration="0.06"
        >
            <div
                v-if="opened"
                key="body"
                :class="[ 'flex flex-col', props.bodyClass ]"
            >
                <slot/>
            </div>
        </AppTransitionCollapse>
    </div>
</template>
